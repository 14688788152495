<template>  

<middleware-sidebar-outline
  :uuid="uuid"
  :middlewareID="middlewareID"
  :middlewareType="middlewareType"
  @close="$emit('close')"
  @shown="$emit('shown')"
  @saveMiddleware="buildAgents"
  v-model="variables"
>
  <div v-if="googleSheetData.pageid">
    <div class="small text-secondary mt-50">
      <b-button variant="none" class="m-0 p-0 google-sheet-account-copying" @click="copyToClipboard(middlewareEmailAccount)">
        <small class="">
          Middleware Google Account: 
          <span class=" mx-50">{{middlewareEmailAccount}}</span> 
          <helper-tooltip tooltipPlacement="right" size="12" innerText="You need to share your Google Sheets with this Google Account in order to allow for FiqOn operations in that Sheet" innter/>
        </small>
      </b-button>
    </div>
    
    <custom-input
      v-if="googleSheetData.sheet_id != undefined && variables"
      :id="`custom-input-sheet-id`"
      class="my-1"
      :possibleValues="variables"
      v-model="googleSheetData.sheet_id.register_1"
    >
      <template #label>
        <span class="text-capitalize">
          Sheet ID 
        </span>
      </template>
    </custom-input>
    <div v-else class="mb-2 mt-2">
      <b-skeleton class="mb-25" width="25%" height="12px"/>
      <b-skeleton class="mb-25" width="100%" height="37px"/>
      <b-skeleton class="mb-25" width="25%" height="14px"/>
    </div>

    <b-form-group v-if="googleSheetData.sheet_id != undefined && variables"
      label-for="action-select"
      label="Action"
    >
      <b-form-select
        id="action-select"
        text-field="label"
        v-model="action"
      >
        <b-form-select-option
          v-for="item in googleSheetsOp" :key="`action-sheet-${item.id}`"
          :value="item.value"
        >
          {{ $t(item.label) }}
        </b-form-select-option>
      </b-form-select>
    </b-form-group>
    <div v-else class="mb-2">
      <b-skeleton class="mb-25" width="25%" height="12px"/>
      <b-skeleton class="mb-25" width="100%" height="37px"/>
      <b-skeleton class="mb-25" width="25%" height="14px"/>
    </div>

    <b-collapse :visible="actionActiveFields.range.includes(action)" appear>
      <custom-input
        v-if="googleSheetData.range != undefined && variables"
        :id="`custom-input-sheet-id`"
        class=""
        :possibleValues="variables"
        v-model="googleSheetData.range.register_1"
      >
        <template #label>
          <span class="text-capitalize">
            Range 
          </span>
        </template>
      </custom-input>
      <div v-else class="mb-2">
        <b-skeleton class="mb-25" width="25%" height="12px"/>
        <b-skeleton class="mb-25" width="100%" height="37px"/>
        <b-skeleton class="mb-25" width="25%" height="14px"/>
      </div>
    </b-collapse>

    <b-collapse :visible="actionActiveFields.value.includes(action)" appear>
      <custom-input
        v-if="googleSheetData.value != undefined && variables"
        :id="`custom-input-sheet-id`"
        :possibleValues="variables"
        v-model="googleSheetData.value.register_1"
      >
        <template #label>
          <span class="text-capitalize">
            Value 
          </span>
        </template>
      </custom-input>
    </b-collapse>

    <b-collapse :visible="actionActiveFields.pageid.includes(action)" appear>
      <custom-input
        v-if="googleSheetData.pageid.register_1 != undefined && variables"
        :id="`custom-input-sheet-id`"
        :possibleValues="variables"
        v-model="googleSheetData.pageid.register_1"
      >
        <template #label>
          <span class="text-capitalize">
            Page ID 
          </span>
        </template>
      </custom-input>
    </b-collapse>

  </div>
</middleware-sidebar-outline>

</template>

<script>
import { 
  BPopover,
  BIcon,
  BButton,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BContainer,
  BSidebar,
  BSpinner,
  BTabs,
  BTab,
  BCardText,
  BCardBody,
  BCard,
  BAvatar,
  BLink,
  BFormSelect,
  BFormSelectOption,
  BFormGroup,
  BSkeleton,
  BCollapse,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import VariablesPanel from "../VariablesPanel.vue";
import { makeToast } from '@/layouts/components/Popups'
import Source from '@/custom/class/Agent/Source'
import DefaultAgent from '@/layouts/components/Transmission/Middleware/Agent/DefaultAgent';
import CustomInput from "@/views/pages/middleware/MiddlewareManagement/Components/CustomInput.vue";
import HelperTooltip from '@/layouts/components/HelperTooltip'
import GoogleSheetsOperation from '@/custom/class/Enum/GoogleSheetsOperation.js'
import MiddlewareSidebarOutline from "@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewareSidebarOutline.vue"
import * as MiddlewareFunctions from  "@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewareFunctions.js"
import Middlewares from "@/custom/class/Enum/Middlewares.js"

import MiddlewaresMixin from '@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewaresMixin.js'

  export default {
    mixins: [MiddlewaresMixin],
    components: {
      BPopover,
      BSidebar,
      BIcon,
      BButton,
      BRow,
      BCol,
      BInputGroup,
      BInputGroupAppend,
      BFormInput,
      BContainer,
      VuePerfectScrollbar,
      VariablesPanel,
      BSpinner,
      BTabs,
      BTab,
      BCardText,
      BCard,
      BAvatar,
      BCardBody,
      BLink,
      CustomInput,
      HelperTooltip,
      BFormSelect,
      BFormGroup,
      BFormSelectOption,
      BSkeleton,
      BCollapse,
      MiddlewareSidebarOutline
      
    },
    props: {
      i18nKey: {
        type: String,
        default: "google_sheets"
      },
      middlewareID: {
        type: Number,
        required: true,
      },
      uuid: {
        type: String,
        required: true,
      },
      value: {
        type: Array,
        required: true,
      }
    },
    data() {
      return {
        uuidMap: {},
        variables: undefined,
        variant_map: undefined,
        script: '',
        googleSheetData: {
          sheet_id: null,
          range: null,
          action: null,
          value: null,
          pageid: null,
        },
        agentList: [],
        isSaving: false,
        version: 0,
        middlewareType: new Middlewares().items.find(el=> el.id == 46),

        nameField: {
          sheet_id: 'SHEET_ID',
          range: 'RANGE',
          action: 'ACTION',
          value: 'VALUE',
          pageid: 'PAGE_ID',
        },

        middlewareEmailAccount: "middlewaregooglesheets@intrepid-league-357113.iam.gserviceaccount.com",

        defaultAgentActionID: 3,
        defaultAgentBlockID: 1,
           
        actionActiveFields:{
          value: [ 'add', 'update',   'addpage', 'insertrow', 'insertcolumn', 'deletecolumn', 'deleterow','insertrow', 'insertcolumn', 'deletecolumn', 'deleterow'],
          range: ['add', 'update', 'get', 'delete'],
          pageid: ['insertrow', 'insertcolumn', 'deletecolumn', 'deleterow'],
        },

        action: null,
      }
    },
    computed: {
      agents: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      googleSheetsOp() {
        return new GoogleSheetsOperation().items
      },
    },
    mounted() {
      this.init();
    },
    methods: {
      init() {
        this.initializeAgents()
      },
      configureVars() {
        this.variant_map = this.$refs[this.getID("variables-panel")].getVariantMap();
        this.variables = this.$refs[this.getID("variables-panel")].getAllVars();
      },
      isObject(item) {
        return item instanceof Object && !(item instanceof Array);
      },
      isArray(item) {
        return item instanceof Array;
      },
      buildAgents() {
        this.isSaving = true;
        this.agentList = [];

        this.setAction()

        Object.keys(this.googleSheetData).forEach((key) => {
          // if (this.googleSheetData[key].register_1.value && key != 'value') {
          this.agentList.push(DefaultAgent.defineToAPI(this.googleSheetData[key], this.middlewareID));
          // }
        })

        this.saveAgents();
      },
      saveAgents() {
        this.$store
        .dispatch("saveAgents", {
          agentList: this.agentList,
          transmissionID: this.$route.params.transmissionID,
        })
        .then((response) => {
          makeToast({
            title: this.$t("agent.toast.create_agents.success.title"),
            text: this.$t("agent.toast.create_agents.success.message"),
            variant: "success",
            icon: "CheckIcon",
          });

          this.$emit("saved", response.data);
          this.isSaving = false
        })
        .catch((error) => {
          this.isSaving = true
        });
      },
      initializeAgents() {
        Object.keys(this.googleSheetData).forEach((key) => {
          this.googleSheetData[key] = MiddlewareFunctions.getValueFrom(
            {
              default: DefaultAgent.setAgent(this, {
                id: null,
                enum_agent_action_id: this.defaultAgentActionID,
                enum_agent_block_id: this.defaultAgentBlockID,
                register_1: {
                  source: "7",
                },
                register_destiny: {
                  source: "20",
                  value: this.nameField[key],
                },
                execution_order: 1,
                fatal_on_fail: true,
              }),
              source: "20",
              value: this.nameField[key],
              type: this.defaultAgentActionID,
              from: {
                source: "register_destiny",
                value: "register_destiny"
              },
              modifier: false,
              multiple: false,
            },
            this.agents
          );
        })

        this.$nextTick(() => {
          this.action = this.googleSheetData.action.register_1.value
        })
      },
      getID(key) {
        if (this.uuidMap[key]) {
          return this.uuidMap[key];
        }

        const uuid = uuidv4();
        this.uuidMap[key] = uuid;

        return uuid;
      },
      sidebarShown() {
        this.$emit("shown")
        this.isSaving = false;

        let el = document.getElementsByTagName("html");
        el[0].classList.add("hide-scrollbar");
      },
      sidebarHidden() {
        this.$emit("close");

        let el = document.getElementsByTagName("html");
        el[0].classList.remove("hide-scrollbar");
      },
      toggleSidebar() {
        this.$root.$emit("bv::toggle::collapse", this.uuid);
      },
      getSourceLabel(id) {
        const source = new Source(id);
        return this.$t(source.label);
      },
      setAction() {
        this.googleSheetData.action.register_1.value = this.action
      },
      copyToClipboard(txt){
        navigator.clipboard.writeText(txt)
        makeToast({
            title: "Success!",
            text: `Middleware Email copyed to Clipboard`,
            variant: "success",
            icon: "CheckIcon",
          });
      }
    }

  }
</script>

<style lang="scss" scoped>

  .sidebar-container{
    max-height: 97vh !important;
    height: fit-content !important;
    overflow-y: auto !important;
  }
  
  .sidebar-fixed-header{
    position: sticky !important;
    top: 0 !important;
    z-index: 2;
  }
  
  .sidebar-content{
    position: relative !important;
    padding-bottom: 20px;
    margin-bottom: 70px !important;
  }
  
</style>


<style lang="scss" scoped>
.google-sheet-account-copying{
  :hover span{
    text-decoration: underline;
  }
  span{
    user-select: all !important;
  }
}
.agent-text {
  display: block !important;
  width: 300px;
}
</style>
<style lang="scss">
.bg-default,
.b-sidebar-header {
  background-color: #151925 !important;
}

.agent-sidebar {
  .b-sidebar-right {
    border-left: solid 1px #0d111c !important;
  }

  .b-sidebar-body {
    overflow: hidden !important;
  }

  .control-height {
    position: relative;
    overflow: auto;

    min-height: 100%;
    padding-bottom: 50px;
  }

  .limit-height {
    height: calc(100% - 215px) !important;
  }

  .saving-adjust-text-position {
    position: relative;
    top: 2px;
  }
}

</style>